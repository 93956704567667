import React from "react";
import Layout from "../../components/Layout";
import Navbar from "../../components/Navbar";
import PageHeader from "../../components/PageHeader";
import Footer from "../../components/Footer";
import ruchi from "../../assets/images/profile/ruchi.png";
const data = {
  name: "Ruchi Shah",
  description:
    "Holding a graduate degree in Psychology, Ruchi has worked as a Counsellor and helped many slow learners overcome their hardships in learning. She has abundant experience of 11 years as a kindergarten teacher. Her able administrative skills had earned her the role of the Centre Co-ordinator in a preschool. Ruchi is also experienced in imparting Jolly Phonics training to children. She is a volunteer for our charitable projects. ",
};

const TeachersDetailsPage = () => {
  return (
    <Layout pageTitle="KWEC | Teachers Details">
      <Navbar />
      <PageHeader title="Our Team" />
      <section className="team-details">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-4">
              <div className="team-one__single">
                <div className="team-one__image">
                  <img src={ruchi} alt="" />
                </div>
                <div className="team-one__content">
                  <h2 className="team-one__name">
                    {data.name}
                  </h2>
                  <p className="team-one__designation">Early Years Educator</p>
                </div>
              </div>
            </div>
            <div className="col-lg-8">
              <div className="team-details__content">
                <h2 className="team-details__title">Read my story</h2>
                <p className="team-details__text">{data.description}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default TeachersDetailsPage;
